
h1.under-Contruction {
    text-align: center;
    font-size: 30px;
}
h1.under-Contruction {
    text-align: center;
    font-size: 30px;
    text-shadow: 1px 2px #3e3936;
}
p.Contruction-p {
    text-align: center;
    font-size: 28px;
}
img.Contruction-img {
    width: 100%;
}