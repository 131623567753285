.translation-box {
    right: 9px;
    bottom: 5px;
    position: absolute;
}

.translation-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
    text-align: right;
    padding-top: 5px;
    padding-bottom: 2px;
}

div#google_translate_element {
    font-family: arial;
    font-size: 11px;
    color: #666;
    white-space: nowrap;
    text-align: right;
    color: transparent !important;
}

.goog-te-gadget {
    font-family: arial;
    font-size: 11px;
    color: #666;
    white-space: nowrap;
}

.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    color: black;
    padding: 5px;
    border-radius: 4px;
}

.skiptranslate.goog-te-gadget {
    text-align: right;
    color: transparent;
}

a.goog-logo-link {
    color: transparent;
}

.translator-holder{
position: relative;
}





@media (min-width: 320px) and (max-width: 767px) {
    .translation-box {
        position: static;
        margin-top: -40px;
        background: rgba(255, 255, 255, 0.9);
        padding: 0 30px;
        margin-bottom: 40px;
    }
    .goog-te-gadget .goog-te-combo {
        width: 100%;
    }
}