
/* Voucher Input Field */
.input-outer1 {
  position: relative;
  margin: 0px 0px;
}
form.form-outer input[type="text"],
form.form-outer input[type="email"],
form.form-outer input[type="tel"],
form.form-outer select {
background: #e8e8e8;
width: 100%;
margin-bottom: 10px;
outline: none;
padding-left: 20px;
border-radius: 10px;
height: 46px;
border: 1px solid #231f20;
}
.errorMsg {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  text-transform: none;
  font-weight: 400;
}

input,
submit,
button {
box-shadow: none !important;
}

/* CheckBox Filed */
input#myCheck {
  display: inline-block;
  width: auto;
}

form.form-outer .removing label {
  font-weight: 700;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Survey Input Fields */

form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
input[type="checkbox"],
input[type="radio"] {
margin: 4px 6px 0 !important;
margin-top: 1px \9;
line-height: normal;
}
form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
form.form-outer.register label {
  text-transform: initial !important;
  font-weight: 400;
  font-size: 15px;
}
label.inline {
  display: inline;
  width: auto !important;
}
form.form-outer label {
  width: 100%;
  text-transform: inherit;
}

input#phone {
background: #ffffff00;
width: 100%;
margin-bottom: 10px;
outline: none;
padding-left: 20px;
border-radius: 10px;
height: 46px;
border: 1px solid #231f20;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}
input#phonenumber {
background: #e8e8e8;
  width: 100%;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 10px;
  height: 46px;
  border: 1px solid #231f20;
}
input#phonenumber2 {
  background: #e8e8e8;
      width: 100%;
      margin-bottom: 10px;
      outline: none;
      padding-left: 20px;
      border-radius: 10px;
      height: 46px;
      border: 1px solid #231f20;
    }
    input#phonenumber3 {
      background: #ffffff00;
          width: 100%;
          margin-bottom: 10px;
          outline: none;
          padding-left: 20px;
          border-radius: 30px;
          height: 46px;
          border: 1px solid #231f20;
        } 